<template>
  <button class="display-flex flex-justify-space-between" @click.stop="onClick" :class="borderClass">
    <div class="left" :class="color">
      {{ displayName }}
    </div>
    <div class="right">
      <span class="material-symbols-outlined" style="transform:rotate(-90deg);color:#726109" :style="{color: fillColor(color)}">
        expand_circle_down
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: "AnswerButton",
  props: {
    displayName: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    borderClass() {
      return `border-${this.color}`;
    }
  },
  methods: {
    onClick() {
      this.$emit("click", this.index);
    },
    fillColor(color) {
      if (color == "green") {
        return "#28c8be";
      } else if (color == "blue") {
        return "#0bbdd6";
      }
      return "#ffffff";
    }
  }
};
</script>

<style scoped>
@import "../../assets/style/adhd-color.css";

button {
  height: 53px;
  width: 100%;
  border-radius: 5px;
}
button .left {
  padding: 12px;
  font-size: 18px;
  font-weight: 700;
}

button .right {
  padding: 12px;
  font-size: 18px;
}

@media (hover: hover) {
  button:hover {
    background-color: #f0fafa;
  }
}
@media (hover: none) {
  button:active {
    background-color: #f0fafa;
  }
}
</style>
