<template>
  <div class="root" :class="`back-${color}`">
    <div class="block">
      <slot name="left">大人のADHDセルフチェック</slot>
    </div>
    <div class="block">
      <slot name="right" />
    </div>
  </div>
</template>
<script>
export default {
  name: "PageTitle",
  props: {
    color: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped>
@import "../../assets/style/adhd-color.css";

.root {
  display: flex;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  justify-content: space-between;
}
.root .block {
  margin: 16px;
}
</style>
