<template>
  <div>
    <page-title :color="color">
      <template v-slot:right>{{ id }}/{{ count }}問</template>
    </page-title>

    <div class="question">Q{{ id }}</div>

    <div class="question-text">
      {{ answerText }}
    </div>

    <div
      class="answer-button"
      v-for="(choice, idx) in choices"
      :native-value="idx"
      :key="idx"
      @click="onNext(idx)"
    >
      <answer-button
        :displayName="choice"
        :index="idx"
        :color="color"
        @click="onNext"
      />
    </div>
    <div class="back" :class="{ hidden: this.id === 1 }">
      <button @click.stop="onBack">前に戻る</button>
    </div>
  </div>
</template>

<script>
import AnswerButton from "./AnswerButton";
import PageTitle from "./PageTitle";

export default {
  name: "AnswerBox",
  props: {
    id: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    answerText: {
      type: String,
      required: true
    },
    choices: {
      type: Array[String],
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  components: { AnswerButton, PageTitle },
  methods: {
    onNext(index) {
      this.$emit("next", index);
    },
    onBack() {
      this.$emit("back");
    }
  }
};
</script>

<style scoped>
.question {
  margin-top: 20px;
  font-size: 36px;
  font-weight: 700;
  font-family: Roboto;
}

.question-text {
  margin: 12px 16px 0px 16px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.answer-button {
  margin: 16px 12px;
}

.back {
  margin: 24px 16px;
  border: 1px solid #dce1e1;
  font-weight: 700;
  font-size: 12px;
  height: 28px;
  width: 100px;
  border-radius: 5px;
}
.hidden {
  visibility: hidden;
}

.back button {
  margin: 4px 8px;
}
@media (hover: hover) {
  .back:hover {
    background-color: #f0fafa;
  }
}
@media (hover: none) {
  .back:active {
    background-color: #f0fafa;
  }
}
</style>
